/**
 * Statuses used for the Workout, Exercises and Sets
 */
export enum TrainingStatusType
{
	None = 0,
	NotStarted = 1,
	InProgress = 2,
	Completed = 3,
	Cancelled = 4,
	Abandoned = 5
}